<template>
  <div class="tw-w-full tw-flex">
    <v-container style="max-width: 75rem" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8">
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 2vh">
    <Logo/>
  </div>
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 5vh">
    <v-card  class="card" elevation="0">
      <p class="account-header"
         :style="[$vuetify.breakpoint.smAndDown ?{marginTop:'3vh'}:{marginTop: '0vh'}]">Create New Password</p>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(changePassword)">
        <div style="display: flex; flex-direction: column; width: 100%;height: 13vh;"
             :style="[$vuetify.breakpoint.smAndDown ?{height:'18vh'}:{height: '13vh'}]">
          <label class="mb-2 label">New Password</label>
          <ValidationProvider
              name="password"
              vid="confirmation"
              :rules="{
                  required: true,
                  regex:  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                  min: 8
            }"
              v-slot="{ errors }">
            <v-text-field
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'text' : 'password'"
                outlined
                v-model="newPassword"
                style="border-radius: 10px"
                height="44"
                color="#FF7A01"
                id="password"></v-text-field>
            <span class="err">{{ errors[0] ? 'Password must contain at least one uppercase, lowercase, digit, special character and must be at least 8 character' : '' }}</span>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; width: 100%;height: 13vh;"
             :style="[$vuetify.breakpoint.smAndDown ?{height:'15vh'}:{height: '13vh'}]">
          <label class="mb-2 label">Confirm Password</label>
          <ValidationProvider
              name="confirm-password"
              rules="required|confirmed:confirmation"
              v-slot="{ errors }">
            <v-text-field
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'text' : 'password'"
                outlined
                v-model="confirmPassword"
                style="border-radius: 10px"
                color="#FF7A01"
                height="44"
                id="confirm-password"></v-text-field>
            <span class="err">{{ errors[0] ? 'Password mismatch' : '' }}</span>
          </ValidationProvider>
        </div>
          <v-btn block rounded elevation="0" :loading="loading" :disabled="invalid"  color="#FF7A01" height="42" style="background-color: #FF7A01;
      color: white;" :style="[$vuetify.breakpoint.smAndDown ?{marginTop:'0vh'}:{marginBottom: '3vh'}]" @click="changePassword">CREATE PASSWORD</v-btn>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</v-container>
  </div>
</template>

<script>
import Logo from "@/reuseable/Logo";
export default {
  name: "ConfirmPassword",
  components:{
    Logo

  },
  data(){
    return{
      loading: false,
      value : '',
      newPassword: "",
      confirmPassword: "",
      passwordError: "",
    }
  },
  methods:{
    validatePassword() {
      if (this.newPassword === this.confirmPassword) {
        return true;
      } else {
        this.passwordError = "Password mismatch";
        return false;
      }
    },

    async changePassword() {
      this.loading = true;
      const data = {
        email: this.$route.query.email,
        password: this.newPassword,
        confirm_password: this.confirmPassword
      }
      if(this.validatePassword){
        try {
          await this.$store.dispatch("onboarding/confirmResetPassword", data)
              .then((res) => {
                console.log(res)
                this.$displaySnackbar({
                  message:'Password Changed Successfully',
                  success: true
                })
              })
          await this.$router.push('/login')

        }
        catch(err){
          this.$displaySnackbar({
            message:err.response.data.detail,
            success: false
          })
        }
        finally {
          this.loading = false

        }

      }
    }
  }
}
</script>

<style scoped lang="scss">
.card{
  display: flex;
  width: 532px;
  height: 440px;
  border: 1px solid #EAEAEA;
  border-radius: 32px;
  flex-direction: column;
  padding: 3% 3%;

}
.account-header{
  color: #292929;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 768px){
    display: flex;
    width: 100%;
    justify-content: center;

  }
}
.desc{
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #818181;
}
.err {
  display: block;
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}
</style>